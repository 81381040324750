<!-- 培训班级列表编辑 -->
<template>
    <div class="EditClassList">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">培训班级列表编辑</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" :rules="rules" id="form-1" class="" inline label-width="150px">
            <el-form-item label="编码">
                <el-input v-model="form.codeNum" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="选择班类" prop="">
               <el-select v-model="form.choiceClass" filterable placeholder="请输入并选择班类" :clearable="true">
                   <el-option v-for="item in form.choiceClassOpt" :key="item.id" :label="item.category_name"
                       :value="item.id">
                   </el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="授课老师" prop="teacher">
                <el-select v-model="form.teacher" filterable placeholder="请输入并选择授课老师" :clearable="true">
                    <el-option v-for="item in form.teacherOpt" :key="item.id" :label="item.fullname"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="协助比例">
                <el-input v-model="form.assist" placeholder="请输入协助比例"></el-input>
            </el-form-item>
            <el-form-item label="学科" prop="subject">
                <el-select v-model="form.subject" filterable placeholder="请输入并选择学科" :clearable="true">
                    <el-option v-for="item in form.subjectOpt" :key="item.id" :label="item.course_name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="授课次数">
                <el-input v-model="form.frequency" placeholder="请输入授课次数"></el-input>
            </el-form-item>
            <el-form-item label="班级名称">
                <el-input v-model="form.className" placeholder="请输入班级名称"></el-input>
            </el-form-item>
            <el-form-item label="班类型">
                <el-select v-model="form.classType" placeholder="请选择班类型" :clearable="true">
                    <el-option v-for="item in form.typeChose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.condition" placeholder="请选择" :clearable="true">
                    <el-option
                      v-for="item in form.conditionChose"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="开班日期">
                <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="form.openClass"
                    type="date"
                    placeholder="请选择开班日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="结业日期">
                <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="form.graduation"
                    type="date"
                    placeholder="请选择结业日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="计划招生人数">
                <el-input v-model="form.planNum" placeholder="请输入计划招生人数"></el-input>
            </el-form-item>
            <el-form-item label="实际学员数">
                <el-input v-model="form.actualNum" placeholder="请输入实际学员数"></el-input>
            </el-form-item>
            <el-form-item label="课时安排说明">
                <el-input v-model="form.planExplain" placeholder="请输入课时安排说明"></el-input>
            </el-form-item>
            <el-form-item label="上课教室">
                <el-select v-model="form.classRoom" filterable placeholder="请输入并选择上课教室" :clearable="true">
                    <el-option v-for="item in form.classRoomOpt" :key="item.id" :label="item.site_name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="学杂费标准(元/人)">
                <el-input v-model="form.outlay" placeholder="请输入学杂费"></el-input>
            </el-form-item>
            <el-form-item label="其中学费(元/人)">
                <el-input v-model="form.tuition" placeholder="请输入其中学费"></el-input>
            </el-form-item>
            <el-form-item label="杂费(元/人)">
                <el-input v-model="form.incidental" placeholder="请输入杂费"></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form: {
                    codeNum:"",//编码
                    choiceClass:"",//选择班类
                    choiceClassOpt:[],//班类选择
                    teacher:"",//授课老师
                    teacherOpt:[],//授课老师选择
                    assist:"",//协助比例
                    subject:"",//学科
                    subjectOpt:[],//学科选择
                    frequency:"",//授课次数
                    className:"",//班级名称
                    classType:"",//班类型
                    typeChose:[
                        {value: 1,label: '春季'},
                        {value: 2,label: '秋季'},
                        {value: 3,label: '寒假'},
                        {value: 4,label: '暑假'},
                        {value: 5,label: '考前培训'}
                    ],//班类型选项
                    condition:"",//状态
                    conditionChose:[
                        {value: 1,label: '开班'},
                        {value: 2,label: '取消'},
                        {value: 3,label: '结业'}
                    ],//状态选项
                    openClass:"",//开班日期
                    graduation:"",//结业日期
                    planNum:"",//计划招生人数
                    actualNum:"",//实际学员数
                    planExplain:"",//课时安排说明
                    classRoom:"",//上课教室
                    classRoomOpt:[],//上课教室选择
                    outlay:"",//学杂费标准
                    tuition:"",//其中学费
                    incidental:""//杂费
                },
                rules: {
                    subject:[
                        { required: true, message: '学科不能为空', trigger: 'blur' },
                    ],
                    teacher:[
                        { required: true, message: '授课老师不能为空', trigger: 'blur' },
                    ],
                },
                bool:false
            }
        },
        created() {
            // 班类数据
            this.$request({
                url:'/api/classcategory/list',
                method:"POST",
                data:{
                    page:1,
                    limit:100
                }
            }).then(res=>{
                if(res.code==1){
                    // console.log(res)
                    this.form.choiceClassOpt=res.data.list
                }
            })
            
            // 授课老师数据
            this.$request({
                url:'/api/humanresources/list',
                method:"POST",
                data:{
                    page:1,
                    limit:100
                }
            }).then(res=>{
                if(res.code==1){
                    // console.log(res)
                    this.form.teacherOpt=res.data.list
                }
            })
            
            // 学科数据
            this.$request({
                url:'/api/course/list',
                method:"POST",
                data:{
                    page:1,
                    limit:100
                }
            }).then(res=>{
                if(res.code==1){
                    console.log(res)
                    this.form.subjectOpt=res.data.list
                }
            })
            
            // 上课教室数据
            this.$request({
                url:'/api/schoolarea/list',
                method:"POST",
                data:{
                    page:1,
                    limit:1000
                }
            }).then(res=>{
                if(res.code==1){
                    console.log(res)
                    this.form.classRoomOpt=res.data.list
                }
            })
            
            // 初次渲染
            this.$request({
                url:'/api/class_/detail',
                method:"POST",
                data:{
                    id:this.$route.query.id
                }
            }).then(res=>{
                if(res.code==1){
                    console.log(res,'初次渲染')
                    let data = res.data
                    let form = this.form
                    form.codeNum=data.number//编码
                    form.choiceClass=data.class_category_id//选择班类
                    form.teacher=data.resources_id//授课老师
                    form.assist=data.ratio//协助比例
                    form.subject=data.course_id//学科
                    form.frequency=data.lecture_number//授课次数
                    form.className=data.class_name//班级名称
                    form.classType=data.class_type//班类型
                    form.condition=data.class_status//状态
                    form.openClass=data.start_time//开班日期
                    form.graduation=data.end_time//结业日期
                    form.planNum=data.planned_size//计划招生人数
                    form.actualNum=data.actual_size//实际学员数
                    form.planExplain=data.class_arrangement//课时安排说明
                    form.classRoom=data.classroom_id//上课教室
                    form.outlay=data.tuition_incidentals//学杂费标准
                    form.tuition=data.tuition//其中学费
                    form.incidental=data.incidentals//杂费
                }
            })
        },
        methods:{
           goBack() { //返回
               this.$router.go(-1);
           }, 
           submitForm(form) { //提交
               this.$refs[form].validate((valid) => {
                   if (valid) {
                       // alert('submit!');
                       // console.log(this.form)
                       if(this.bool){
                           return
                       }
                       this.bool=true
                       let form = this.form
                       this.$request({
                           url:'/api/class_/edit',
                           method:'POST',
                           data:{
                               id:this.$route.query.id,
                               number:form.codeNum,//班级编码
                               course_id:form.subject,//学科id
                               class_name:form.className,//班级名称
                               class_type:form.classType,//班级类型
                               class_status:form.condition,//班级状态
                               resources_id:form.teacher,//授课老师id
                               class_category_id:form.choiceClass,//班级类别id
                               tuition_incidentals:form.outlay,//学杂费
                               tuition:form.tuition,//学费
                               incidentals:form.incidental,//杂费
                               planned_size:form.planNum,//计划人数
                               actual_size:form.actualNum,//实际人数
                               start_time:form.openClass,//开班日期
                               end_time:form.graduation,//结业日期
                               lecture_number:form.frequency,//授课次数
                               classroom_id:form.classRoom,//上课教室id
                               class_arrangement:form.planExplain,//课时安排说明
                               ratio:form.assist//协作比例
                           }
                       }).then(res=>{
                           if(res.code==1){
                               this.$message({
                                 message: '编辑列表成功',
                                 type: 'success'
                               })
                               setTimeout(() => {
                               	this.goBack()	
                               }, 1500)
                           }else{
                               this.$message({
                                 message: res.msg,
                                 type: 'error'
                               })
                               setTimeout(() => {
                               	this.goBack()	
                               }, 1500)
                           }
                       }).catch(()=>{
                           this.bool=false
                       })
                   } else {
                       console.log('error submit!!');
                       return false;
                   }
               });
           },
           resetForm(form) { //取消
               this.goBack()
               this.$refs[form].resetFields();
           },
        }
    }
</script>

<style scoped="scoped">
    .EditClassList{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    
    .el-select {
        width: 300px;
    }
    
    ::v-deep .el-input.is-disabled .el-input__inner {
        background-color: #FFFFFF;
    }
    
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
